import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { ProtectedGateway } from '../outlets/ProtectedGateway'
import { Profile } from '../pages/Profile'
import { Invitations } from '../pages/Invitations'
import { keldaBlackTheme } from '../theme/KeldaPortalBlackTheme'
import {
  LOCAL_ADMIN_ROUTE,
  LOCAL_COMPANY_ROUTE,
  LOCAL_PROFILE_ROUTE,
  LOCAL_INVITATIONS_ROUTE,
  LOCAL_INVITE_ROUTE,
  LOCAL_PASSWORD_ROUTE,
  LOCAL_USERS_ROUTE,
} from '../configs/routes'
import { Invite } from '../pages/Invite'
import { GuestGateway } from '../outlets/GuestGateway'
import { AdminLayout } from '../outlets/AdminLayout'
import { AppLayout } from '../outlets/AppLayout'
import { Password } from '../pages/Password'
import { Organizations } from '../pages/Organizations'
import { Users } from '../pages/Users'

const APP = (): JSX.Element => {
  return (
    <ThemeProvider theme={keldaBlackTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route element={<ProtectedGateway />}>
            <Route element={<AppLayout />}>
              <Route path={LOCAL_PROFILE_ROUTE} element={<Profile />}></Route>
              <Route path={LOCAL_PASSWORD_ROUTE} element={<Password />}></Route>
              <Route path={LOCAL_ADMIN_ROUTE} element={<AdminLayout />}>
                <Route path={LOCAL_USERS_ROUTE} element={<Users />}></Route>
                <Route
                  path={LOCAL_COMPANY_ROUTE}
                  element={<Organizations />}
                ></Route>
                <Route
                  path={LOCAL_INVITATIONS_ROUTE}
                  element={<Invitations />}
                ></Route>
                <Route
                  index
                  element={<Navigate to={LOCAL_INVITATIONS_ROUTE} />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<GuestGateway />}>
            <Route path={LOCAL_INVITE_ROUTE} element={<Invite />} />
          </Route>
          <Route path="*" element={<Navigate to={LOCAL_PROFILE_ROUTE} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default APP
