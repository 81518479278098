import React, { useEffect } from 'react'
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { KeldaLogoWhite } from '../../resources/images'
import { KeldaTypography, StyledButton } from './elements'
import { useKeycloak } from '@react-keycloak/web'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProfile,
  resetProfileAction,
} from '../../store/slices/profile/slice'

/** TODO: Need to separate out styles and other configurations */

const Header = (): JSX.Element => {
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const location = useLocation()

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  useEffect(() => setAnchorElUser(null), [location])

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    dispatch(resetProfileAction())
    keycloak.logout()
  }

  const profile = useSelector(selectProfile)

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box
              component="img"
              sx={{
                height: 38,
              }}
              alt="Kelda Portal Logo"
              src={KeldaLogoWhite}
            />
            <KeldaTypography
              variant="h6"
              noWrap
              sx={{ ml: 4, mr: 10, display: 'flex' }}
            >
              KeldaPortal
            </KeldaTypography>

            <Box sx={{ flexGrow: 1, display: 'flex' }}></Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <StyledButton
                  onClick={handleOpenUserMenu}
                  endIcon={
                    <Avatar>
                      {profile.firstName.substring(0, 2).toUpperCase()}
                    </Avatar>
                  }
                >
                  {`${profile.firstName} ${profile.lastName}`}
                </StyledButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <ListItem>Account</ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {profile.firstName.substring(0, 2).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${profile.firstName} ${profile.lastName}`}
                    secondary={profile.email}
                  />
                </ListItem>
                <ListItem></ListItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar></Toolbar>
    </>
  )
}

export default Header
