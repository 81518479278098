import React from 'react'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

import { StyledButtonWrapper, StyledForm, StyledTextField } from './elements'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { CreateGroupRequestPayload } from '../../api/groups/models'
import { createGroupAction } from '../../store/slices/group/actions'

interface GroupCreateFormProps {
  postSubmitActionHandler: () => void
}

type FormValues = CreateGroupRequestPayload

/** intital form value */
const INITIAL_FORM_VALUE: FormValues = {
  name: '',
  description: '',
}

const GroupCreateForm: React.FC<GroupCreateFormProps> = ({
  postSubmitActionHandler,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUE,
    onSubmit: (values: FormValues) => {
      dispatch(
        createGroupAction({
          ...values,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Organization created successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            postSubmitActionHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Failed to create organization!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  return (
    <div>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledTextField
          id="name"
          name="name"
          label="Organization Name"
          margin="normal"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <StyledTextField
          id="description"
          name="description"
          label="Description"
          margin="normal"
          size="medium"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
        <StyledButtonWrapper>
          <Button color="secondary" variant="contained" type="submit">
            Add Organization
          </Button>
        </StyledButtonWrapper>
      </StyledForm>
    </div>
  )
}

export default GroupCreateForm
