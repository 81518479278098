import { RemoveCircleOutline } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { selectCurrentGroup } from '../../store/slices/groupUser/selectors'
import { removeGroupUserAction } from '../../store/slices/groupUser/actions'

interface RemoveGroupUserButtonObj {
  userId: string
  userEmail: string
}

const RemoveGroupUserButton = ({
  userId,
  userEmail,
}: RemoveGroupUserButtonObj) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { groupId, groupName } = useSelector(selectCurrentGroup)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    dispatch(
      removeGroupUserAction({
        userId: userId,
        groupId: groupId,
        groupName: groupName,
        postSuccessHandler: () => {
          enqueueSnackbar(
            'User removed from organization successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar(
            'Failed to remove user from organization!',
            ERROR_NOTIFICATION_OPTIONS
          )
        },
      })
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <RemoveCircleOutline color="error" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm the action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you really want to remove ${userEmail} from ${groupName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RemoveGroupUserButton
