import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getUserlistFromServer,
  toggleUserActivationInServer,
} from '../../../api/userlist/requests'
import {
  fetchUserlistAction,
  FetchUserlistPayload,
  toggleUserActivationAction,
  ToggleUserActivationPayload,
} from './actions'
import { setUserActivationStatusAction, setUserlistAction } from './slice'

function* fetchUserlistAsync(action: {
  payload: FetchUserlistPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, offset, limit, search } =
    action.payload
  try {
    const response = yield call(() =>
      getUserlistFromServer({ offset, limit, search })
    )
    yield put(setUserlistAction(response.data))
    yield call(() => postSuccessHandler(response.totalCount))
  } catch (exception) {
    console.error(exception)
    yield call(errorHandler)
  }
}

function* toggleUserActivationAsync(action: {
  payload: ToggleUserActivationPayload
}): SagaIterator {
  const { postSuccessHandler, errorHandler, ...rest } = action.payload
  try {
    yield call(() => toggleUserActivationInServer(rest))
    yield call(postSuccessHandler)
    yield put(setUserActivationStatusAction(rest))
  } catch (exception) {
    yield call(errorHandler)
    console.error(exception)
  }
}

export function* fetchUserlistSaga(): SagaIterator {
  yield takeLatest(fetchUserlistAction, fetchUserlistAsync)
  yield takeLatest(toggleUserActivationAction, toggleUserActivationAsync)
}
