import { AcceptInvitationObj } from '../../api/invitations/models'
import * as yup from 'yup'
import { useFormik } from 'formik'
import {
  StyledButtonWrapper,
  StyledCircularProgress,
  StyledForm,
  StyledImg,
  StyledInviteHeaderWrapper,
  StyledTextInput,
  StyledSubtitle,
} from './elements'
import { Button, Typography } from '@mui/material'
import { acceptServerInvitation } from '../../api/invitations/requests'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { ERROR_NOTIFICATION_OPTIONS } from '../../configs/constants'
import { useConfirmModal } from '../../hooks/useConfirmModal'
import { useKeycloak } from '@react-keycloak/web'
import { ConfirmModal } from '../ConfirmModal'

/** interface to describe form value */
export interface FormValues extends AcceptInvitationObj {
  confirmPassword: string
}

/** interface to describe component props */
interface InvitationAcceptFormProps {
  formValues: FormValues
}

const validationSchema = yup.object({
  email: yup
    .string()
    .default('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const InvitationAcceptForm: React.FC<InvitationAcceptFormProps> = ({
  formValues,
}: InvitationAcceptFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const { keycloak } = useKeycloak()
  const { enqueueSnackbar } = useSnackbar()
  const { getConfirmModalProps, openConfirmationModal } = useConfirmModal({
    title: 'Account Created',
    description: 'You can now login using your credentials.',
    okText: 'Redirect To Login',
    hideCancel: true,
    onOk: () => (window.location.href = keycloak.createLoginUrl()),
  })

  const formik = useFormik<FormValues>({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      setLoading(true)
      try {
        await acceptServerInvitation(values)
        openConfirmationModal()
      } catch (exception) {
        console.error(exception)
        enqueueSnackbar(
          'Request Failed! Try again after some time.',
          ERROR_NOTIFICATION_OPTIONS
        )
      }
      setLoading(false)
    },
  })

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledInviteHeaderWrapper>
        <StyledImg src={`/kelda-logo-white.svg`} alt="" />
        <Typography variant="h4" noWrap={true}>
          Kelda Portal
        </Typography>
        <StyledSubtitle variant="subtitle1" noWrap={true}>
          Complete your profile
        </StyledSubtitle>
      </StyledInviteHeaderWrapper>
      <StyledTextInput
        id="email"
        name="email"
        label="Email"
        size="small"
        margin="normal"
        disabled
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <StyledTextInput
        id="groupName"
        name="groupName"
        label="Organization"
        size="small"
        margin="normal"
        disabled
        value={formik.values.groupName}
        onChange={formik.handleChange}
        error={formik.touched.groupName && Boolean(formik.errors.groupName)}
        helperText={formik.touched.groupName && formik.errors.groupName}
      />
      <StyledTextInput
        id="firstName"
        name="firstName"
        label="First Name"
        size="small"
        margin="normal"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <StyledTextInput
        id="lastName"
        name="lastName"
        label="Last Name"
        size="small"
        margin="normal"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
      <StyledTextInput
        id="password"
        name="password"
        label="New Password"
        size="small"
        margin="normal"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <StyledTextInput
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        size="small"
        margin="normal"
        type="password"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
      />
      <StyledButtonWrapper>
        <ConfirmModal {...getConfirmModalProps} />
        <Button
          disabled={loading}
          color="secondary"
          variant="contained"
          type="submit"
        >
          {loading && <StyledCircularProgress size={18} />}Accept Invitation
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  )
}

export default InvitationAcceptForm
