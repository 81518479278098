import { all, fork } from 'redux-saga/effects'
import * as invitationSaga from './slices/invitation/sagas'
import * as groupSaga from './slices/group/sagas'
import * as groupUserSaga from './slices/groupUser/sagas'
import * as profileSaga from './slices/profile/sagas'
import * as userlistSaga from './slices/userlist/sagas'
import * as activeUsersSaga from './slices/activeUsers/sagas'

const sagas = {
  ...invitationSaga,
  ...groupSaga,
  ...groupUserSaga,
  ...profileSaga,
  ...userlistSaga,
  ...activeUsersSaga,
}

export default function* rootSaga() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield all(Object.keys(sagas).map((key) => fork((sagas as any)[key])))
}
