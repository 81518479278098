import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AttributesObj {
  group_id: string
  group_name: string
}

export interface UserObj {
  id: string
  createdTimestamp: number
  username: string
  enabled: boolean
  totp: boolean
  emailVerified: boolean
  firstName: string
  lastName: string
  email: string
  attributes: AttributesObj
  disableableCredentialTypes: []
  requiredActions: []
  notBefore: number
  access: unknown
}

interface ToggleUserActivationObj {
  userId: string
  enabled: boolean
}

type UserlistState = UserObj[]

export const reducerName = 'userlist'

export const initialUserlistState: UserlistState = []

const userlistSlice = createSlice({
  name: reducerName,
  initialState: initialUserlistState,
  reducers: {
    setUserlist(state, action: PayloadAction<UserObj[]>) {
      return [...action.payload]
    },
    setUserActivationStatus(
      state,
      action: PayloadAction<ToggleUserActivationObj>
    ) {
      const user = state.find(({ id }) => id === action.payload.userId)
      if (user !== undefined) {
        user.enabled = action.payload.enabled
      }
    },
  },
})

/** export the userlist reducer actions */
export const {
  setUserlist: setUserlistAction,
  setUserActivationStatus: setUserActivationStatusAction,
} = userlistSlice.actions

/** export the userlist default reducer */
export default userlistSlice.reducer
