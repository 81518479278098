import { SERVER_USERS_ENDPOINT } from '../../configs/endpoints'
import { axioService, PUT } from '../../services/axios'
import { AddRemoveGroupUserPayload } from './models'

export const addGroupUserInServer = async (
  userPayload: AddRemoveGroupUserPayload
) => {
  const response = await axioService(
    PUT,
    SERVER_USERS_ENDPOINT + '/' + userPayload.userId + '/add-group',
    {
      group_id: userPayload.groupId,
    },
    true
  )
  return response.status
}

export const removeGroupUserInServer = async (
  userPayload: AddRemoveGroupUserPayload
) => {
  const response = await axioService(
    PUT,
    SERVER_USERS_ENDPOINT + '/' + userPayload.userId + '/remove-group',
    {
      group_id: userPayload.groupId,
    },
    true
  )
  return response.status
}
