import { configureStore } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'
import createReducer from './rootReducers'
import rootSaga from './rootSagas'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { fetchInvitationsAction } from './slices/invitation/actions'

/** method to create store */
const configureAppStore = (initialState = {}) => {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  const { run: runSaga } = sagaMiddleware

  /** setup redux-injectors to load reducers dynamically*/
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ]

  /** sync between tabs only if predicate is true */
  const reduxStateSyncConfig = {
    predicate: () => false, // stop syncing between tabs
  }

  /** configuration related redux persist */
  const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [], // add reducer to persist specific slices to localstorage
  }

  const store = configureStore({
    reducer: persistReducer(persistConfig, createReducer()),
    /** setup redux toolkit to use saga instead of thunk */
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            fetchInvitationsAction.type,
          ],
        },
      }),
      createStateSyncMiddleware(reduxStateSyncConfig),
      sagaMiddleware,
    ],
    preloadedState: initialState,
    devTools: true,
    enhancers,
  })

  sagaMiddleware.run(rootSaga)
  return store
}

/** initialize the root store */
const rootStore = configureAppStore()

initMessageListener(rootStore)

export const persistor = persistStore(rootStore)

export type Store = ReturnType<typeof rootStore.getState>

/** export the default root store  */
export default rootStore
