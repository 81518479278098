import { useKeycloak } from '@react-keycloak/web'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { fetchProfileAction } from '../../store/slices/profile/actions'

const ProtectedGateway = (): JSX.Element => {
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  if (!keycloak.authenticated) {
    window.location.href = keycloak.createLoginUrl()
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(fetchProfileAction())
    }
  }, [keycloak.authenticated])

  return <Outlet />
}

export default ProtectedGateway
