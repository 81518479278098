import { AddGroupUserButton } from '../AddGroupUserButton'
import { RemoveGroupUserButton } from '../RemoveGroupUserButton'

export const REMOVE_GROUP_USER_COLUMNS_CONFIG = [
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <RemoveGroupUserButton userId={row.id} userEmail={row.email} />
    },
  },
]

export const ADD_GROUP_USER_COLUMNS_CONFIG = [
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <AddGroupUserButton userId={row.id} userEmail={row.email} />
    },
  },
]
