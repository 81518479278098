import { CorporateFare, Key, Launch, ListAlt } from '@mui/icons-material'
import MailIcon from '@mui/icons-material/Mail'
import PeopleIcon from '@mui/icons-material/People'
import { Divider, List, Toolbar } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { KEYCLOAK_KP_ADMIN_ROLE, EXTERNAL_LINKS } from '../../configs/constants'
import {
  LOCAL_ADMIN_ROUTE,
  LOCAL_COMPANY_ROUTE,
  LOCAL_INVITATIONS_ROUTE,
  LOCAL_USERS_ROUTE,
  LOCAL_PASSWORD_ROUTE,
  LOCAL_PROFILE_ROUTE,
} from '../../configs/routes'
import { NavItem, NavItemProps } from '../NavItem'
import { StyledBox, StyledDrawer } from './elements'

interface ExternalLinkObj {
  link: string
  label: string
}

const USER_NAV_ITEMS: NavItemProps[] = [
  {
    link: `/${LOCAL_PROFILE_ROUTE}`,
    label: 'Personal Info',
    icon: <ListAlt />,
  },
  {
    link: `/${LOCAL_PASSWORD_ROUTE}`,
    label: 'Credential',
    icon: <Key />,
  },
]

const ADMIN_NAV_ITEMS: NavItemProps[] = [
  {
    link: `/${LOCAL_ADMIN_ROUTE}/${LOCAL_USERS_ROUTE}`,
    label: 'Users',
    icon: <PeopleIcon />,
  },
  {
    link: `/${LOCAL_ADMIN_ROUTE}/${LOCAL_COMPANY_ROUTE}`,
    label: 'Organizations',
    icon: <CorporateFare />,
  },
  {
    link: `/${LOCAL_ADMIN_ROUTE}/${LOCAL_INVITATIONS_ROUTE}`,
    label: 'Invitations',
    icon: <MailIcon />,
  },
]

const EXTERNAL_LINK_ITEMS: NavItemProps[] = EXTERNAL_LINKS.map(
  (navItem: ExternalLinkObj) => ({
    link: navItem.link,
    label: navItem.label,
    icon: <Launch />,
    target: '_blank',
  })
)

const Sidebar = (): JSX.Element => {
  const { keycloak } = useKeycloak()
  const isAdmin = keycloak.hasRealmRole(KEYCLOAK_KP_ADMIN_ROLE)

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Toolbar />
      <StyledBox>
        <List>
          {/*
          We're moving the external links to the top of Sidebar
          temporarily. This should be revisited once we add more
          links.
          */}
          {EXTERNAL_LINK_ITEMS.map((navItem, index) => (
            <NavItem
              key={`external-link-${index}`}
              {...navItem}
              isBoldFont={true}
            ></NavItem>
          ))}
        </List>
      </StyledBox>
      <Divider />
      <List>
        {USER_NAV_ITEMS.map((navItem, index) => (
          <NavItem key={`user-nav-${index}`} {...navItem}></NavItem>
        ))}
      </List>
      {isAdmin && <Divider />}
      {isAdmin && (
        <StyledBox>
          <List>
            {ADMIN_NAV_ITEMS.map((navItem, index) => (
              <NavItem key={`admin-nav-${index}`} {...navItem}></NavItem>
            ))}
          </List>
        </StyledBox>
      )}
      <Divider />
    </StyledDrawer>
  )
}

export default Sidebar
