import { ToggleSwitch } from '../../components/ToggleSwitch'
import { UserOrganizationListCell } from '../../components/UserOrganizationListCell'

export const USERLIST_COLUMNS_CONFIG = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Organization (s)',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <UserOrganizationListCell groups={row.groups} />
    },
  },
  {
    Header: 'Enabled',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => {
      return <ToggleSwitch user={row} />
    },
  },
]
