import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { toggleUserActivationAction } from '../../store/slices/userlist/actions'
import { UserObj } from '../../store/slices/userlist/slice'

interface ToggleSwitchObj {
  user: UserObj
}

const ToggleSwitch = ({ user }: ToggleSwitchObj) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const handleClickOpen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleConfirm = () => {
    dispatch(
      toggleUserActivationAction({
        userId: user.id,
        enabled: !user.enabled,
        postSuccessHandler: () => {
          enqueueSnackbar(
            'User updated successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar('Failed to update user!', ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Switch
        checked={user.enabled}
        onChange={handleClickOpen}
        color="secondary"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm the action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you really want to ${
              user.enabled ? 'disable' : 'enable'
            } the user "${user.email}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ToggleSwitch
