import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Column, useTable } from 'react-table'
import { StyledTable, StyledTableRow } from './elements'

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  columns: Column<{}>[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: {}[]
  maxHeight?: number
  stickyHeader?: boolean
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  maxHeight = 2000,
  stickyHeader = false,
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })
  /* react-table props provide key; and so, no need to explicity mention key on the maps*/
  /* eslint-disable react/jsx-key */
  return (
    <TableContainer component={Paper} style={{ maxHeight: maxHeight }}>
      <StyledTable {...getTableProps()} stickyHeader={stickyHeader}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <TableCell
                  {...column.getHeaderProps()}
                  align={index === 0 ? 'left' : 'center'}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <StyledTableRow {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      align={index === 0 ? 'left' : 'center'}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default Table
