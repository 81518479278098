import { createAction } from '@reduxjs/toolkit'

const USERLIST_PREFIX = 'USERLIST'

export interface FetchUserlistPayload {
  offset: number
  limit: number
  search: string
  inclGroupId?: string
  exclGroupId?: string
  postSuccessHandler: (totalCount: number) => void
  errorHandler: () => void
}

export interface ToggleUserActivationPayload {
  userId: string
  enabled: boolean
  postSuccessHandler: () => void
  errorHandler: () => void
}

/** actions */

export const fetchUserlistAction = createAction<FetchUserlistPayload>(
  `${USERLIST_PREFIX}/API/FETCH_USERLIST`
)

export const toggleUserActivationAction =
  createAction<ToggleUserActivationPayload>(
    `${USERLIST_PREFIX}/API/TOGGLE_USER_ACTIVATION`
  )
