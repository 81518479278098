import * as yup from 'yup'
import { useFormik } from 'formik'
import {
  StyledButtonWrapper,
  StyledForm,
  StyledHeaderWrapper,
  StyledTextInput,
  StyledSubtitle,
} from './elements'
import { Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { changeProfilePasswordInServer } from '../../api/profile/requests'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { ConfirmModal } from '../ConfirmModal'
import { useModal } from '../../hooks/useModal'

/** interface to describe form value */
export interface FormValues {
  password: string
  confirmPassword: string
}

const INITIAL_FORM_VALUES: FormValues = {
  password: '',
  confirmPassword: '',
}

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const PasswordUpdateForm = (): JSX.Element => {
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()
  const { enqueueSnackbar } = useSnackbar()

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: validationSchema,
    onSubmit: openModalHandler,
  })

  const onOk = async () => {
    try {
      await changeProfilePasswordInServer(formik.values.password)
      enqueueSnackbar(
        'Password updated successfully',
        SUCCESS_NOTIFICATION_OPTIONS
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (exception: any) {
      const errorMessage = exception?.response?.data?.error || ''
      enqueueSnackbar(
        `Failed to update password: ${errorMessage}`,
        ERROR_NOTIFICATION_OPTIONS
      )
    }
    formik.resetForm()
    closeModalHandler()
  }

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledHeaderWrapper>
        <Typography variant="h4" noWrap={true}>
          Change Password
        </Typography>
        <StyledSubtitle variant="subtitle1" noWrap={true}>
          Update your login password
        </StyledSubtitle>
      </StyledHeaderWrapper>
      <StyledTextInput
        id="password"
        name="password"
        label="New Password"
        size="small"
        margin="normal"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <StyledTextInput
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        size="small"
        margin="normal"
        type="password"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
      />
      <StyledButtonWrapper>
        <ConfirmModal
          open={modalStatus}
          title="Are you sure that you want to update your password?"
          onCancel={closeModalHandler}
          onOk={onOk}
          okText="Yes"
          cancelText="Cancel"
        />
        <Button color="secondary" variant="contained" type="submit">
          Change
        </Button>
      </StyledButtonWrapper>
    </StyledForm>
  )
}

export default PasswordUpdateForm
