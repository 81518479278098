import { OptionsObject } from 'notistack'

export const COMPANY_NAME = 'KELDA DYNAMICS'

/** External Links */

function getExternalLinks() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ENV?.REACT_APP_EXTERNAL_LINKS
}

export const EXTERNAL_LINKS = JSON.parse(
  getExternalLinks() || process.env.REACT_APP_EXTERNAL_LINKS
)

/** keycloak */
export const KEYCLOAK_INIT_OPTIONS = { onLoad: 'check-sso' }
export const KEYCLOAK_KP_ADMIN_ROLE =
  process.env.REACT_APP_KEYCLOAK_KP_ADMIN_ROLE || 'kp_admin'

/** keycloak server config */

function getKeycloakServerConfig() {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_URI: (window as any).ENV?.REACT_APP_KEYCLOAK_URI,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_REALM: (window as any).ENV?.REACT_APP_KEYCLOAK_REALM,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_CLIENT_ID: (window as any).ENV?.REACT_APP_KEYCLOAK_CLIENT_ID,
  }
}

const keycloakConfig = getKeycloakServerConfig()

export const KEYCLOAK_ENDPOINT =
  keycloakConfig.KEYCLOAK_URI ||
  process.env.REACT_APP_KEYCLOAK_URI ||
  'http://localhost:8080/auth'
export const KEYCLOAK_REALM =
  keycloakConfig.KEYCLOAK_REALM ||
  process.env.REACT_APP_KEYCLOAK_REALM ||
  'master'
export const KEYCLOAK_CLIENT_ID =
  keycloakConfig.KEYCLOAK_CLIENT_ID ||
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
  'demo-client-id'

/** notifications */
const anchorOrigin: OptionsObject['anchorOrigin'] = {
  vertical: 'top',
  horizontal: 'center',
}
export const DEFAULT_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'default',
  anchorOrigin,
}

export const SUCCESS_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'success',
  anchorOrigin,
}

export const ERROR_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'error',
  anchorOrigin,
}

export const INFO_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'info',
  anchorOrigin,
}

export const WARNING_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'warning',
  anchorOrigin,
}

export const ADMIN_ROLE = 'admin'
export const USER_ROLE = 'user'
